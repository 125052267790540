import "./style.scss";
import domReady from "@wordpress/dom-ready";

function updateMockupLayout() {
  const mockups = document.querySelectorAll(".mockup-inner-blocks");
  const itemSelector = ".wp-block-intempus-mockup-content-box";

  mockups.forEach((mockup) => {
    if (!mockup || mockup.classList.contains("mockup-init")) return;

    mockup.classList.add("mockup-init");

    const leftColumn = document.createElement("div");
    leftColumn.classList.add("mockup-column-left");

    const rightColumn = document.createElement("div");
    rightColumn.classList.add("mockup-column-right");

    const items = mockup.querySelectorAll(itemSelector);

    const half = Math.ceil(items.length / 2);

    items.forEach((item, index) => {
      if (index < half) {
        leftColumn.append(item);
      } else {
        rightColumn.append(item);
      }
    });

    mockup.append(leftColumn);
    mockup.append(rightColumn);
  });
}

function mockupClickable() {
  const complex = document.querySelectorAll(".complex");

  //Dont do anything, if its not on the page.
  if (!complex.length) return null;

  function activateItem(showcase, item) {
    //1. When activating a new one, we need to check if some of the other ones is active
    const itemsActive = showcase.querySelectorAll(".item.active");
    const mockup = showcase.querySelectorAll("#mockup-insert")[0];
    const dropdownButton = showcase.querySelector(".mockup-dropdown-button button");
    const title = item.querySelector(".mockup-content-right h3");
    const mobileItemInserter = showcase.querySelector(".mobile-item-inserter");

    mobileItemInserter.innerHTML = item.innerHTML;

    if (dropdownButton && title) {
      dropdownButton.innerHTML = title.textContent;
    }

    //2. If true, loop through and disable active
    if (itemsActive.length !== 0) {
      itemsActive.forEach(function (active) {
        active.classList.remove("active");
      });
    }

    item.classList.add("active");

    //4. Add the file with the file type
    let url = item.getAttribute("data-file");
    let type = item.getAttribute("data-file-type");
    let activeOne = mockup.querySelectorAll(".active")[0];

    if (activeOne) {
      activeOne.classList.remove("active");
    }

    if (type === "image") {
      let image = mockup.querySelector("img");
      image.src = url;
      image.classList.add("active");
    } else {
      //video
      let video = mockup.querySelector("video");

      video.src = url;
      video.classList.add("active");
      video.play();
    }
  }

  function dropdown(showcase) {
    const button = showcase.querySelector(".mockup-dropdown-button button");
    const dropdownElem = showcase.querySelector(".mockup-dropdown-wrapper");

    if (!button || !dropdownElem) return;
    const token = "active-dropdown";

    button.addEventListener("click", () => {
      if (dropdownElem.classList.contains(token)) {
        dropdownElem.classList.remove(token);
      } else {
        dropdownElem.classList.add(token);
      }
    });

    const items = showcase.querySelectorAll(".item");
    items.forEach((item) =>
      item.addEventListener("click", () => {
        dropdownElem.classList.remove(token);
      })
    );
  }

  complex.forEach(function (showcase) {
    let items = showcase.querySelectorAll(".item");

    dropdown(showcase);

    items.forEach(function (item, index) {
      // activate first item initially
      if (index === 0) {
        activateItem(showcase, item);
      }

      item.addEventListener("click", activateItem.bind(this, showcase, item));
    });
  });
}

domReady(() => {
  updateMockupLayout();
  mockupClickable();
});
